import AxiosInterceptor from "../utils/AxiosInterceptor";

const apiUsers = {
  add: (value) => AxiosInterceptor().post(`/userAccess/register`, value),
  edit: (value) =>
    AxiosInterceptor().post(`/adminUserManegement/updateUser`, value),
  delete: (value) => AxiosInterceptor().post(`/admin/user/delete`, value),
  getAll: (values) =>
    AxiosInterceptor().post(`/adminUserManegement/getAllUser`, values),
  getById: (values) =>
    AxiosInterceptor().post(`/adminUserManegement/getUser`, values),

  getUserTransaction: (values) =>
    AxiosInterceptor().post(`/adminUserManegement/purchaseHistory`, values),
  verification: (values) =>
    AxiosInterceptor().post(`/adminUserManegement/activeRejectModel`, values),
  accountStatus: (values) =>
    AxiosInterceptor().post(`/admin/user/activeRejectModelManegement`, values),
  changeStatus: (values) =>
    AxiosInterceptor().post(`/admin/user/change-status`, values),
  getAllGallery: (values) =>
    AxiosInterceptor().post(`/adminUserManegement/getAllGallery`, values),
  getAllAlbums: (values) =>
    AxiosInterceptor().post(`/adminUserManegement/getAllAlbums`, values),
};

export default apiUsers;
