import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link as MLink,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { MESSAGE } from "../../utils/validationMessage";
import palette from "../../theme/palette";
import apiAuth from "../../services/authService";
import { NOSPACE_REGEX } from "../../utils/constants";
import { complexEmailRegex } from "../../utils/emailCheck";
import errorHandler from "../../utils/errorHandler";
import { useState } from "react";
// ----------------------------------------------------------------------

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(MESSAGE.REQUIRED)
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
    .test("is-email", MESSAGE.EMAIL, (val) => complexEmailRegex(val))
    .max(255),
});

export default function Login() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      apiAuth
        .resendOtp(values)
        .then((res) => {
          openModal();
        })
        .catch((err) => {
          errorHandler(err);
          setSubmitting(false);
        });
    },
  });
  const handleClose = () => {
    setIsModalOpen(false);
    navigate("/otp", { state: { email: values.email }, replace: true });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Typography variant="h3" sx={{ m: 2 }} align="center">
        Forget Password
      </Typography>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          {isModalOpen && (
            <Dialog
              open={isModalOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Password Reset Email"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  A password reset email has been sent to your registered Email
                  ID.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Ok</Button>
              </DialogActions>
            </Dialog>
          )}
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ my: 2 }}
        >
          Reset Password
        </LoadingButton>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 2 }}
        >
          <MLink
            variant="subtitle2"
            underline="hover"
            to="/login"
            as={Link}
            color={palette.primary.main}
          >
            Back to Login
          </MLink>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
