import axios from "axios";
import { API_BASE } from "../utils/constants";

const API_URL = API_BASE + "adminAccess/";

const apiAuth = {
  login: (value) => axios.post(API_URL + "login", value),

  // forgot: (value) => axios.post(API_URL + 'change-password', value),

  resendOtp: (value) => axios.post(API_URL + "password-reset", value),
  verifyOtp: (value) => axios.post(API_URL + "change-password", value),
  resetPassword: (value) => axios.post(API_URL + "change-password", value),

  // resetPassword: (value) => axios.post(API_URL + "updatePassword", value),
};

export default apiAuth;
