import { useRoutes, Navigate } from "react-router-dom";
import React, { lazy, Suspense } from "react";

// Layouts
import PublicLayout from "../layouts/public";
import Page404 from "../pages/content/error/Page404";

// Auth Pages
import LoginPage from "../pages/auth/LoginPage";
import ForgetForm from "../pages/auth/ForgetPage";
import OtpPage from "../pages/auth/OtpPage";
import ResetPassword from "../pages/auth/ResetPasswordPage";
import ChangePassword from "../pages/auth/ChangePassword";



const AuthRouting = () => {
  const routes = [
    {
      element: <PublicLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "login", element: <LoginPage /> },
        { path: "forgot", element: <ForgetForm /> },
        { path: "otp", element: <OtpPage /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "*", element: <Navigate to="/login" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/login" replace /> },
  ];

  return useRoutes(routes);
};

export default AuthRouting;
