import { CardHeader, Container, Card, CardContent } from "@mui/material";
import BackButton from "../components/Buttons/BackButton";

export default function CardLayout({ title, children }) {
  return (
    <Container>
      <Card sx={{ overflowY: "scroll" }}>
        <CardHeader title={<BackButton text={title} variant="h6" />} />
        <CardContent>{children}</CardContent>
      </Card>
    </Container>
  );
}
