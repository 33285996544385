import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { MESSAGE } from "../../utils/validationMessage";
import apiAuth from "../../services/authService";
import { PASSWORDS_REGEX } from "../../utils/constants";
import errorHandler from "../../utils/errorHandler";
import {
  Link as MLink,
  Stack,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import palette from "../../theme/palette";
import CardLayout from "../../layouts/CardLayout";

// ----------------------------------------------------------------------

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(MESSAGE.REQUIRED)
    .matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
  cpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(MESSAGE.REQUIRED),
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const { state } = useLocation();

    useEffect(() => {
      if (!state?.email) {
        toast.error("Please enter a email");
        navigate("/login");
      }
    }, [navigate, state?.email]);

  const formik = useFormik({
    initialValues: { cpassword: "", password: "" },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      apiAuth
        .resetPassword({
          email: state?.email,
          password: values?.password,
          // confirmPassword: values.cpassword,
        })
        .then((res) => {
          toast.success(res?.data?.msg);
          navigate("/dashboard");
        })
        .catch((err) => {
          errorHandler(err);
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

 
  return (
    <FormikProvider value={formik}>
      <Typography variant="h3" sx={{ mb: 2 }} align="center">
         Reset Password
      </Typography>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
         
              <TextField
                name="password"
                label="Password"
                {...getFieldProps("password")}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                name="cpassword"
                label="Confirm Password"
                {...getFieldProps("cpassword")}
                error={Boolean(touched.cpassword && errors.cpassword)}
                helperText={touched.cpassword && errors.cpassword}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ my: 2 }}
            >
              Change Password
            </LoadingButton>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ my: 2 }}
            >
              <MLink
                variant="subtitle2"
                underline="hover"
                to="/dashboard"
                as={Link}
                color={palette.primary.main}
              >
                Back to Dashboard
              </MLink>
            </Stack>
          </Form>
    </FormikProvider>
  );
}
