import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("token");

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: token || "",
    modal: false,
    model_pending: 0,
  },
  reducers: {
    login(state, action) {
      state.token = action.payload;
    },
    modal(state, action) {
      if (action.payload) {
        state.modal = true;
        state.model_pending = action.payload;
      }
    },
    modalClose(state, action) {
      state.modal = false;
    },
    logout(state) {
      state.token = "";
    },
  },
});

export const { login, logout, modal, modalClose } = authSlice.actions;

export default authSlice.reducer;
