import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link as MLink, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";
import LoadingOverlay from "../../components/shared/Backdrop";
import { MESSAGE } from "../../utils/validationMessage";
import errorHandler from "../../utils/errorHandler";
import { NOSPACE_REGEX } from "../../utils/constants";
import apiAuth from "../../services/authService";
import palette from "../../theme/palette";

// ----------------------------------------------------------------------

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .required(MESSAGE.REQUIRED)
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
    .min(6)
    .max(6),
});

export default function OtpPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();

  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (!state?.email) {
      toast.error("Please enter a email");
      navigate("/login");
    }
  }, [navigate, state?.email]);

  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      apiAuth
        .verifyOtp({ email: state?.email, token: values.otp })
        .then((res) => {
          toast.success(res?.data?.msg);
          navigate("/change-password", {
            state: { otp: values?.otp, email: state?.email },
          });
        })
        .catch((err) => {
          errorHandler(err);
          setSubmitting(false);
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setSubmitting,
  } = formik;

  const handleChange = (newValue) => {
    setOtp((prev) => {
      const data = newValue;
      setFieldValue("otp", data);
      return data;
    });
  };

  const resendOtp = () => {
    setIsLoading(true);
    apiAuth
      .resendOtp({ email: state?.email })
      .then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.msg);
      })
      .catch((err) => {
        setIsLoading(false);
        errorHandler(err);
      });
  };

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <FormikProvider value={formik}>
        <br />
        <Typography variant="h3" sx={{ m: 2 }} align="center">
          OTP
        </Typography>
        <br />

        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {/* <TextField name="otp" label="OTP" {...getFieldProps('otp')} error={Boolean(touched.otp && errors.otp)} helperText={touched.otp && errors.otp} /> */}
            <MuiOtpInput length={6} value={otp} onChange={handleChange} />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ my: 2 }}
          >
            <MLink
              variant="subtitle2"
              underline="hover"
              to="/forgot"
              onClick={resendOtp}
              color={palette.primary.main}
            >
              Resend Otp?
            </MLink>
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={otp?.length !== 6}
          >
            {" "}
            Verify{" "}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
