import axios from 'axios';
import { API_BASE } from '../utils/constants';
import AxiosInterceptor from "../utils/AxiosInterceptor";
const API_URL = API_BASE + "adminAccess/";

const apiAuth = {
  getProfile: (value) =>
    AxiosInterceptor().post(API_URL + "get-profile", value),
  updateProfile: (value) =>
    AxiosInterceptor().post(API_URL + "updateProfile", value),
  resetPassword: (value) =>
    AxiosInterceptor().post(API_URL + "updatePassword", value),
};

export default apiAuth;
