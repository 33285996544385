import { toast } from "react-toastify";

const errorHandler = (err) => {
  console.log("err", err)
  if (err.response?.data) {
    const { msg, status, error } = err.response?.data;

    if (status === 0 && Array.isArray(error) && error.length > 0) {
      error.map((err) => toast.error(err.msg));
      return;
    }
    if (msg) {
      toast.error(msg);
      return;
    }

    toast.error("Something went wrong!");
  } else {
    toast.error("An error occurred");
  }
};

export default errorHandler;
